import "./index.css";
import "@fidelix/fx-miranda/base.css";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { UikitProvider } from "@fidelix/fx-miranda";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { dynamicActivate } from "./i18n";
import { router } from "./router";

async function init() {
  await dynamicActivate("en");

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const root = createRoot(document.getElementById("root") as Element);

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <I18nProvider i18n={i18n}>
          <UikitProvider>
            <RouterProvider router={router} />
          </UikitProvider>
        </I18nProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}

init();

/**
 * Clear any service workers that were registered by Create React App.
 *
 * TODO: delete this block of code once this change has been deployed
 * and all old service workers have been unregistered. We might want to add
 * a service worker for this app later, but for now, it's not needed.
 */
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();

    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}
